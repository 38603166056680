<template>
  <div class="hero master-w">
    <div class="hero-body">
      <div class="tile is-ancestor">
        <div class="tile is-vertical is-8">
          <div class="tile">
            <div class="tile is-parent is-vertical">
              <article class="tile is-child notification">
                <p class="subtitle">CA Total</p>
                <p class="title is-1">{{ totalMAD | numberSpace }} €</p>
                <p class="title is-4">{{ totalEUR | numberSpace }} MAD</p>
              </article>
              <article class="tile is-child notification">
                <p class="subtitle">Part du CA</p>
                <p class="subtitle">
                  SPA: <b>{{ resSpaP }}%</b> ({{
                    totalSpaMAD | numberSpace
                  }}
                  €)<br />
                  CAR: <b>{{ resCarP }}%</b> ({{
                    totalCarMAD | numberSpace
                  }}
                  €)<br />
                  OOM: <b>{{ 0 }}%</b> ({{ 0 }} €)<br />
                  SLC: <b>{{ 0 }}%</b> ({{ 0 }} €)<br />
                </p>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child notification">
                <p class="subtitle">Chart</p>
                <Chart :chartData="dataCollection"></Chart>
              </article>
            </div>
          </div>
          <div class="tile is-parent">
            <article class="tile is-child notification">
              <p class="title"></p>
              <p class="subtitle"></p>
              <div class="content">
                <!-- Content -->
              </div>
            </article>
          </div>
        </div>
        <div class="tile is-parent">
          <article class="tile is-child notification">
            <div class="content mail-tile">
              <p class="title">EMAIL ({{ contactList.length }})</p>
              <p class="subtitle"></p>
              <div class="content contactList">
                <p v-for="mail of contactList" :key="mail.email">
                  {{ mail.email }}
                </p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../firebase";
import axios from "axios";
import Chart from "./Chart";

export default {
  components: {
    Chart,
  },
  data() {
    return {
      contactList: [],
      resSpa: [],
      resCar: [],
      rate: null,
    };
  },
  firestore() {
    return {
      contactList: db.collection("contactList"),
      resSpa: db.collection("reservationSpa").where("status", "==", "paid"),
      resCar: db.collection("locationVoiture").where("status", "==", "paid"),
    };
  },
  filters: {
    numberSpace: function (x) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    },
  },
  computed: {
    totalSpaMAD: function () {
      var total = 0;
      this.resSpa.forEach((doc) => {
        total = total + doc.price;
      });
      return total;
    },
    totalCarMAD: function () {
      var total = 0;
      this.resCar.forEach((doc) => {
        total = total + doc.price;
      });
      return total;
    },
    totalMAD: function () {
      var total = 0;
      this.resSpa.forEach((doc) => {
        total = total + doc.price;
      });
      this.resCar.forEach((doc) => {
        total = total + doc.price;
      });
      return total;
    },
    totalEUR: function () {
      var total = 0;
      this.resSpa.forEach((doc) => {
        total = total + doc.price;
      });
      this.resCar.forEach((doc) => {
        total = total + doc.price;
      });
      return Math.round((total * 10 + Number.EPSILON) * 100) / 100;
    },
    resSpaP: function () {
      return (
        Math.round(
          ((100 * this.totalSpaMAD) / (this.totalSpaMAD + this.totalCarMAD) +
            Number.EPSILON) *
            100
        ) / 100
      );
    },
    resCarP: function () {
      return (
        Math.round(
          ((100 * this.totalCarMAD) / (this.totalSpaMAD + this.totalCarMAD) +
            Number.EPSILON) *
            100
        ) / 100
      );
    },
    dataCollection: function () {
      return {
        labels: ["SPA", "VOITURE"],
        datasets: [
          {
            label: "test",
            backgroundColor: ["#a3c7c9", "#889d9e"],
            data: [this.resSpaP, this.resCarP],
          },
        ],
      };
    },
  },
  mounted() {
    axios
      .get(
        "https://free.currconv.com/api/v7/convert?q=MAD_EUR&compact=ultra&apiKey=b71d4dae9a81470b5c1e"
      )
      .then((res) => (this.rate = res.data.MAD_EUR));
  },
};
</script>

<style>
article.tile {
  background-color: white;
  box-shadow: 0px 7px 19px 5px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding-bottom: 60px;
}
.tile.is-child {
  padding: 40px;
}
.tile .subtitle {
  margin-bottom: 70px;
}
.master-w {
  height: calc(100vh - 70px);
  overflow: scroll;
}
.mail-tile {
  height: 100%;
}
.contactList {
  padding-top: 20px;
  /* background-color: red; */
  height: calc(100% - 40px);
  overflow: scroll;
}
.contactList p {
  margin: 0px !important;
}
</style>